import React from 'react';
import { API } from '../../@onocomm-cart';
import {
	Container,
	Row,
Col,
Image,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';
//import AnchorLink from 'react-anchor-link-smooth-scroll';//追加200122
import './android.css';
class OthersMobileitem extends React.Component {
	//-----------追加 200122-----------
				constructor(props) {
						super(props);
						this.state = {
							scrolloffset       : '92',
							cognitoid      :  ''
						}
						this._getData     = this._getData.bind(this);
					}
					async _getData() {
						//ログインの確認
						try{
							//Cognito IDの取得
							const user         = await API.Auth.currentAuthenticatedUser();
							const cognitoid    = user.username;

							//ログイン済みの場合フラグ
							if(cognitoid){
								this.setState({
									cognitoid  : cognitoid,
								 });
							}
						}
						catch(error) {
							//
						}
					}
					componentDidMount () {
						this._getData();
					}
				componentWillMount () {
						window.addEventListener('resize', () => {
								var wwidth = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;
								if(wwidth<767){
										this.setState({	scrolloffset   : '63',	});
								}
						})
				}
	//-----------追加 200122-----------
	render() {
		return (
							<div>
							<Header/>
				<main className={this.state.cognitoid ? 'others members' : 'others'}>
<Container>
	 <div className="mb-5">
	   	<Image src="/img/mobileitem/top_mobileitem.jpg" alt="オンラインストア限定モバイルアイテム" fluid />

	 </div>
	 <div className="mb-3">
	 		<Image src="/img/mobileitem/top_mobileitem02.png" alt="" fluid />
	 </div>

</Container>
<Container>
	 <div id="smp_chara-list" className="my-5">
		<picture>
	<source media="(max-width: 767px)" srcSet="/img/mobileitem/ttl_chara_sp.png" />
		 <Image className="my-5" src="/img/mobileitem/ttl_chara.png" alt="キャラクターで探す" fluid />
	 </picture>
	 <ul className="mb-3">
	 <li><a href="/category/キャラクターから探す-ごろごろにゃんすけ-モバイル用品/" className="">ごろごろにゃんすけ</a></li>
	 <li><a href="/category/キャラクターから探す-コソコソさん-モバイル用品/" className="">コソコソさん</a></li>
	 <li><a href="/category/キャラクターから探す-しばんばん-モバイル用品/" className="">しばんばん</a></li>
	 <li><a href="/category/キャラクターから探す-くろしばんばん-モバイル用品/" className="">くろしばんばん</a></li>
	 <li><a href="/category/キャラクターから探す-うさぎのムーちゃん-モバイル用品/" className="">うさぎのムーちゃん</a></li>
	 <li><a href="/category/キャラクターから探す-ツンダちゃん-モバイル用品/" className="">ツンダちゃん</a></li>
	 <li><a href="/category/キャラクターから探す-ばいばいべあ-モバイル用品/" className="">ばいばいべあ</a></li>
	 <li><a href="/category/キャラクターから探す-ぴよこ豆-モバイル用品/" className="">ぴよこ豆</a></li>
	 <li><a href="/category/キャラクターから探す-ようこそかわうそ-モバイル用品/" className="">ようこそかわうそ</a></li>
	 <li><a href="/category/キャラクターから探す-ゆるあにまる-モバイル用品/" className="">ゆるあにまる</a></li>
	 </ul>
	 <p>※背面ガラスケース ラウンド型・サンドケースは商品数が多いため含まれておりませんので、下記の各ページよりご覧ください。</p>
	</div>
	 </Container>
	 <Container>
		<div className="mt-5">
		<picture>
	<source media="(max-width: 767px)" srcSet="/img/mobileitem/ttl_item_sp.png" />
		 <Image className="my-5" src="/img/mobileitem/ttl_item.png" alt="アイテムで探す" fluid />
	 </picture>
	 <h4 className="title-border-right">受注生産商品</h4>
	 <Row>
	 {/*<Col xs={12} sm={6} className="mb-3"><a href="/others/android/" className="opa06"><Image className="" src="/img/mobileitem/btn_cc.png" alt="クリアケース" fluid /></a></Col>*/}
	 <Col xs={12} sm={6} className="mb-3"><a href="/others/strapcase/" className="opa06"><Image className="" src="/img/mobileitem/btn_strap.png" alt="ケース" fluid /></a></Col>
	 <Col xs={12} sm={6} className="mb-3"><a href="/others/glasscase/" className="opa06"><Image className="" src="/img/mobileitem/btn_gcr.png" alt="ケース" fluid /></a></Col>
	 
	 <Col xs={12} sm={6} className="mb-3"><a href="/others/sandcase/" className="opa06"><Image className="" src="/img/mobileitem/btn_sc-2.png" alt="ケース" fluid /></a></Col>
	 <Col xs={12} sm={6} className="mb-3"><a href="/others/mobilebattery/#mobilebattery" className="opa06"><Image className="" src="/img/mobileitem/btn_mb.png" alt="充電器" fluid /></a></Col>
	 <Col xs={12} sm={6} className="mb-3"><a href="/others/mobilebattery/#wireless" className="opa06"><Image className="" src="/img/mobileitem/btn_wc.png" alt="充電器" fluid /></a></Col>
	 <Col xs={12} sm={6} className="mb-3"><a href="/others/airpods/" className="opa06"><Image className="" src="/img/mobileitem/btn_apc.png" alt="ケース" fluid /></a></Col>

	 </Row>
	 <h4 className="title-border-right mt-5">予約商品</h4>
	 <Row>
	 <Col xs={12} sm={6} className="mb-3"><a href="/category/アイテムから探す-モバイル用品-スマホリング/" className="opa06"><Image className="" src="/img/mobileitem/btn_sr.png" alt="スマホリング" fluid /></a></Col>
	 <Col xs={12} sm={6} className="mb-3"><a href="/search/iPhoneケース 【アクリル】/" className="opa06"><Image className="" src="/img/mobileitem/btn_ac.png" alt="iPhoneケース" fluid /></a></Col>
	 <Col xs={12} sm={6} className="mb-3"><a href="/category/アイテムから探す-手帳型スマホケース-M/" className="opa06"><Image className="" src="/img/mobileitem/btn_sc.png" alt="手帳型スマホケース" fluid /></a></Col>
	 <Col xs={12} sm={6} className="mb-3"><a href="/search/iPhoneケース【背面ガラス(スクエア型)】/" className="opa06"><Image className="" src="/img/mobileitem/btn_gcs.png" alt="iPhoneケース" fluid /></a></Col>
	 <Col xs={12} sm={6} className="mb-3"><a href="/category/アイテムから探す-モバイル用品-スマホグリップ/" className="opa06"><Image className="" src="/img/mobileitem/btn_grip.png" alt="スマホグリップ" fluid /></a></Col>
	 {/*<Col xs={12} sm={6} className="mb-3"><a href="/others/mobilebattery/#mobilebattery" className="opa06"><Image className="" src="/img/mobileitem/btn_mb.png" alt="充電器" fluid /></a></Col>
	 <Col xs={12} sm={6} className="mb-3"><a href="/others/mobilebattery/#wireless" className="opa06"><Image className="" src="/img/mobileitem/btn_wc.png" alt="充電器" fluid /></a></Col>
	 <Col xs={12} sm={6} className="mb-3"><a href="/others/humidifier/" className="opa06"><Image className="" src="/img/mobileitem/btn_humidifier.png" alt="加湿器" fluid /></a></Col>*/}
	 </Row>

		 </div>
		 <picture>
		 <source media="(max-width: 767px)" srcSet="/img/android/ac-caution_sp.png" />
		 		<Image className="mt-5" src="/img/android/ac-caution.png" alt="ご注意" fluid />
	</picture>

	 <picture>
 <source media="(max-width: 767px)" srcSet="/img/android/ac-caution2_sp.png" />
		<Image className="mb-5" src="/img/android/ac-caution2.png" alt="ご注意" fluid />
	</picture>
	
</Container>
<Container className="mb-4 ">
	 <div className="oshirase bg_gray">
	 <Row>
	 <Col xs={12}>
	 <h3><i className="fas fa-exclamation-triangle"></i> 使用上のご注意 <i className="fas fa-exclamation-triangle"></i></h3>
	 <ul className="mb-5">
	<li>※本来の用途以外には使用しないでください。</li>
	<li>※スマホケースは、スマートフォン本体へのキズや汚れを完全に防ぐものではありません。</li>
	<li>※本製品のご使用中に伴う、スマートフォンの紛失、故障、内部データの破損に関しては一切の責任を負いかねますので、ご了承ください。</li>
	<li>※スマホケースを装着したまま充電スタンドの使用や、SIMカード、SDカードの出し入れはできません。</li>
	</ul></Col></Row>
	 </div>
	 <div className="text-right mt-5"><a href="../../stationary_index/">トップページに戻る <i class="fas fa-arrow-right"></i></a></div>
</Container>
</main>
									<Footer/>
										</div>
		);
	}
}

export default withRouter(OthersMobileitem);
