import React from 'react';
import { API } from '../../@onocomm-cart';
import {
	Container,
	Row,
Col,
Image,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';
import AnchorLink from 'react-anchor-link-smooth-scroll';//追加200122

class OthersFukubukuro extends React.Component {
	//-----------追加 200122-----------
				constructor(props) {
						super(props);
						this.state = {
							scrolloffset       : '92',
							cognitoid      :  ''
						}
						this._getData     = this._getData.bind(this);
					}
					async _getData() {
						//ログインの確認
						try{
							//Cognito IDの取得
							const user         = await API.Auth.currentAuthenticatedUser();
							const cognitoid    = user.username;

							//ログイン済みの場合フラグ
							if(cognitoid){
								this.setState({
									cognitoid  : cognitoid,
								 });
							}
						}
						catch(error) {
							//
						}
					}
					componentDidMount () {
						this._getData();
					}
				componentWillMount () {
						window.addEventListener('resize', () => {
								var wwidth = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;
								if(wwidth<767){
										this.setState({	scrolloffset   : '63',	});
								}
						})
				}
	//-----------追加 200122-----------
	render() {
		return (
							<div>
							<Header/>
				<main className={this.state.cognitoid ? 'others members' : 'others'}>
<Container>
	 <Row>
		 	  <div className="mb-5 pb-3">
			   <Image src="/img/fukubukuro/fuku_head.jpg" alt="福袋" fluid /></div></Row>
			   
			   <Row>
			<Col xs={{ span: 4, offset: 4 }}><Image className="my-3" src="/img/fukubukuro/f_attention.png" alt="必ずお読みください。" fluid /></Col></Row>
			   
			   <Row>
	 <Col xs={12} className="">

		 		 <div className="oshirase mb-5 py-5">
	<h3>下記、あらかじめご理解の上、お申込みいただきますようお願い申し上げます。</h3>
	<p className="my-5"><span className="marker_y">2024年福袋は抽選販売となります。<br />
お申込みページより必要事項をご入力の上、お申込みください。</span><br />
【申込受付期間】2024年1月1日（月）昼12：00～1月5日（金）午前10：00<br />
<br />
当選者の方には1月9日（火）にご注文用の商品ページURLを記載したメールを送信させていただきます。<br />
お申込みの際に「＠mindwave-store.com」からのメールを受信できるよう、必ずドメイン指定受信設定をお願いいたします。<br />
メールが届かない場合、改めての当選のご案内はできかねますので、あらかじめご了承ください。</p>
<ul>
<li>※抽選のため、お申込み順は当落には一切関係ございません。</li>
<li>※アクセス状況によりましては、回線が込み合う場合がございます。時間を置いてアクセスしてください。</li>
<li>※当選された場合、お1人様各種類1点のみのご購入となります。2点以上を購入されている場合は、数量を変更させていただきます。</li>
<li>※ご注文確定メール後のキャンセル、不良品以外の返品・交換はお受けできません。</li>
<li>※ラッピング不可のため、ラッピングを選択しご注文されている場合はラッピングのみキャンセルとさせていただきます。</li>
<li>※ご注文後、約2週間程度でのお届けとなります。日時指定のご希望にはそえない場合がございますので、あらかじめご了承ください。</li>
<li>※gmailのメールセキュリティが強化されているため、gmailアドレスをご利用の場合は、必ず「store@mindwave-store.com」のアドレスを指定受信設定してください。</li>
</ul>
		 </div>
	</Col>
</Row>
	<Row><div className="mt-5 pt-5">
		 		<Image src="/img/fukubukuro/ttl_item.png" alt="一覧" fluid className="mb-5" />
		 </div></Row>
			 {/* */}
			 <Row className="mt-5 mb-5">
	 			<Col xs={6} sm={3} ><AnchorLink offset={this.state.scrolloffset} href="#cate01" className="btn-pink w-100 btn btn-smp btn-lg pt-3 pb-3">シール＆テープ
</AnchorLink></Col>
	 			<Col xs={6} sm={3} ><AnchorLink offset={this.state.scrolloffset} href="#cate02" className="btn-pink w-100 btn btn-smp btn-lg pt-3 pb-3">文房具</AnchorLink></Col>
	 			<Col xs={6} sm={3} ><AnchorLink offset={this.state.scrolloffset} href="#cate03" className="btn-pink w-100 btn btn-smp btn-lg pt-3 pb-3">キャラクター</AnchorLink></Col>
				 <Col xs={6} sm={3} ><AnchorLink offset={this.state.scrolloffset} href="#cate04" className="btn-pink w-100 btn btn-smp btn-lg pt-3 pb-3">スペシャル</AnchorLink></Col>
	 	 </Row>

</Container>
<Container>
	<div id="cate01" className="mt-5">
	<picture>
	　   <source media="(max-width: 767px)" srcSet="/img/fukubukuro/ttl_fuku04_sp.png" />
		 <Image className="mt-5" src="/img/fukubukuro/ttl_fuku04.png" alt="シール" fluid />
	 </picture>
	</div>

	<Row className="">
	 		<Col xs={12} sm={6} className="mt-5 text-center" ><h4 className="text-center">《限定数：300個》とことんシール</h4><Image src="https://images.mindwave-store.com/public/F2024_3000A_sub01.jpg" alt="福袋" className="bdrimg" fluid /></Col>
			<Col xs={12} sm={6} className="mt-5" ><h4 className="text-center">《限定数：120個》もっとシール</h4><Image src="https://images.mindwave-store.com/public/F2024_5000A_sub01.jpg" alt="福袋" className="bdrimg" fluid /></Col>
			<Col xs={12} sm={6} className="mt-5" ><h4 className="text-center">《限定数：60個》クリアテープ</h4><Image src="https://images.mindwave-store.com/public/F2024_3000B_sub01.jpg" alt="福袋" className="bdrimg" fluid /></Col>
	 	</Row>
</Container>
<Container>
	<div id="cate02" className="mt-5">
	<picture>
	　   <source media="(max-width: 767px)" srcSet="/img/fukubukuro/ttl_fuku01_sp.png" />
		 <Image className="mt-5" src="/img/fukubukuro/ttl_fuku01.png" alt="文房具" fluid />
	 </picture>
	</div>

	<Row className="">
	 		<Col xs={12} sm={6} className="mt-5 text-center" ><h4 className="text-center">《限定数：100個》大人かわいい</h4><Image src="https://images.mindwave-store.com/public/F2024_1000A_sub01.jpg" alt="福袋" className="bdrimg" fluid /></Col>
			<Col xs={12} sm={6} className="mt-5" ><h4 className="text-center">《限定数：100個》ことりまち商店街</h4><Image src="https://images.mindwave-store.com/public/F2024_1000B_sub01.jpg" alt="福袋" className="bdrimg" fluid /></Col>
	 	</Row>
</Container>
<Container>
	<div id="cate03" className="mt-5">
	<picture>
	　   <source media="(max-width: 767px)" srcSet="/img/fukubukuro/ttl_fuku02_sp.png" />
		 <Image className="mt-5" src="/img/fukubukuro/ttl_fuku02.png" alt="キャラクター" fluid />
	 </picture>
	</div>

	<Row className="">
	 		
			<Col xs={12} sm={6} className="mt-5" ><h4 className="text-center">《限定数：100個》しばんばん</h4><Image src="https://images.mindwave-store.com/public/F2024_3000C_sub01.jpg" alt="福袋" className="bdrimg" fluid /></Col>
			<Col xs={12} sm={6} className="mt-5" ><h4 className="text-center">《限定数：50個》うさぎのムーちゃん</h4><Image src="https://images.mindwave-store.com/public/F2024_3000D_sub01.jpg" alt="福袋" className="bdrimg" fluid /></Col>
			<Col xs={12} sm={6} className="mt-5 text-center" ><h4 className="text-center">《限定数：50個》ごろごろにゃんすけ</h4><Image src="https://images.mindwave-store.com/public/F2024_5000B_sub01.jpg" alt="福袋" className="bdrimg" fluid /></Col>
			<Col xs={12} sm={6} className="mt-5 text-center" ><h4 className="text-center">《限定数：40個》ぴよこ豆</h4><Image src="https://images.mindwave-store.com/public/F2024_5000C_sub01.jpg" alt="福袋" className="bdrimg" fluid /></Col>
			
	 	</Row>
</Container>
<Container>
	<div id="cate04" className="mt-5">
	<picture>
	　   <source media="(max-width: 767px)" srcSet="/img/fukubukuro/ttl_fuku03_sp.png" />
		 <Image className="mt-5" src="/img/fukubukuro/ttl_fuku03.png" alt="スペシャル" fluid />
	 </picture>
	</div>

	<Row className="">
	 		<Col xs={12} sm={6} className="mt-5 text-center" ><h4 className="text-center">《限定数：30個》にゃんすけ スペシャル</h4><Image src="https://images.mindwave-store.com/public/F2024_10000A_sub01.jpg" alt="福袋" className="bdrimg" fluid /></Col>
			<Col xs={12} sm={6} className="mt-5" ><h4 className="text-center">《限定数：30個》しばんばん スペシャル</h4><Image src="https://images.mindwave-store.com/public/F2024_10000B_sub01.jpg" alt="福袋" className="bdrimg" fluid /></Col>
	 	</Row>
</Container>
{/* 
<Container>

	<div id="cate02" className="mt-5">
	<picture>
	　   <source media="(max-width: 767px)" srcSet="/img/fukubukuro/ttl_5000_sp.png" />
		 <Image className="mt-5" src="/img/fukubukuro/ttl_5000.png" alt="5000" fluid />
	 </picture>
	</div>
	<Row className="">
	 		<Col xs={12} sm={6} className="mt-5" ><a href="/item/F2022_5000A/" className="opa06"><Image src="https://images.mindwave-store.com/public/F2022_5000A_sub01.jpg" alt="" className="bdrimg" fluid /></a></Col>
			<Col xs={12} sm={6} className="mt-5" ><a href="/item/F2022_5000B/" className="opa06"><Image src="https://images.mindwave-store.com/public/F2022_5000B_sub01.jpg" alt="" className="bdrimg" fluid /></a></Col>
	 	</Row>

</Container>
<Container>
	  <div id="cate03" className="mt-5">
		<picture>
	<source media="(max-width: 767px)" srcSet="/img/fukubukuro/ttl_3000_sp.png" />
		 <Image className="mt-5" src="/img/fukubukuro/ttl_3000.png" alt="3000" fluid />
	 </picture>
	</div>

	 	 <Row className="">
	 		<Col xs={12} sm={4} className="mt-5" ><a href="/item/F2022_3000A/" className="opa06"><Image src="https://images.mindwave-store.com/public/F2022_3000A_sub01.jpg" alt="" className="bdrimg" fluid /></a></Col>
			<Col xs={12} sm={4} className="mt-5" ><a href="/item/F2022_3000B/" className="opa06"><Image src="https://images.mindwave-store.com/public/F2022_3000B_sub01.jpg" alt="" className="bdrimg" fluid /></a></Col>
			<Col xs={12} sm={4} className="mt-5" ><a href="/item/F2022_3000C/" className="opa06"><Image src="https://images.mindwave-store.com/public/F2022_3000C_sub01.jpg" alt="" className="bdrimg" fluid /></a></Col>
	 	
	 		<Col xs={12} sm={4} className="mt-5" ><a href="/item/F2022_3000D/" className="opa06"><Image src="https://images.mindwave-store.com/public/F2022_3000D_sub01.jpg" alt="" className="bdrimg" fluid /></a></Col>
			<Col xs={12} sm={4} className="mt-5" ><a href="/item/F2022_3000E/" className="opa06"><Image src="https://images.mindwave-store.com/public/F2022_3000E_sub01.jpg" alt="" className="bdrimg" fluid /></a></Col>
			<Col xs={12} sm={4} className="mt-5" ><a href="/item/F2022_3000F/" className="opa06"><Image src="https://images.mindwave-store.com/public/F2022_3000F_sub01.jpg" alt="" className="bdrimg" fluid /></a></Col>
			<Col xs={12} sm={4} className="mt-5" ><a href="/item/F2022_3000G/" className="opa06"><Image src="https://images.mindwave-store.com/public/F2022_3000G_sub01.jpg" alt="" className="bdrimg" fluid /></a></Col>
	 	</Row>
</Container>
<Container>
	 <div id="cate04" className="mt-5">
		<picture>
	<source media="(max-width: 767px)" srcSet="/img/fukubukuro/ttl_1000_sp.png" />
		 <Image className="mt-5" src="/img/fukubukuro/ttl_1000.png" alt="1000" fluid />
	 </picture>
	</div>

		<Row className="mb-5">
			<Col xs={12} sm={{ span: 4, offset: 2 }} className="mt-5"><a href="/item/F2022_1000A/" className="opa06"><Image src="https://images.mindwave-store.com/public/F2022_1000A_sub01.jpg" alt="" className="bdrimg" fluid /></a></Col>
			<Col xs={12} sm={4} className="mt-5"><a href="/item/F2022_1000B/" className="opa06"><Image src="https://images.mindwave-store.com/public/F2022_1000B_sub01.jpg" alt="" className="bdrimg" fluid /></a></Col>
			
	 </Row>
</Container>
*/}
<Container className="mt-5">
<Row className="my-5">
<Col xs={12} sm={{ span: 10, offset: 1 }} ><a href="https://www.mindwave.co.jp/ec_form2401/" className="btn-pink w-100 btn btn-lg my-5 py-3 fw-bold"><i className="fa fa-arrow-right"></i> お申込みページへ</a></Col>
</Row>
</Container>

{/*<Container>
	 <div className="mb-5 pb-5">
	   <picture>
	 　		<source media="(max-width: 767px)" srcSet="/img/fukubukuro/f_tokuten_sp.jpg" />
	 		<Image className="mt-5" src="/img/fukubukuro/f_tokuten_pc.jpg" alt="購入特典あり！" fluid />
	 	</picture>
	 </div>
	 <div className="text-right mt-5"><a href="../../stationary_index/">トップページに戻る <i class="fas fa-arrow-right"></i></a></div>
</Container>*/}


				</main>
									<Footer/>
										</div>
		);
	}
}

export default withRouter(OthersFukubukuro);
