import React from 'react';
import {
	Container,
	Row,
	Col,
	Image,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Header from '../@Include/Header';
import Footer from '../@Include/Footer';


class GuidePayment extends React.Component {
	render() {
		return (
							<div>
							<Header/>
				<main>
				<Container>
					<div className="head-logo sec">
						<a className="" href="/stationary_index/"><Image src="/svg/logo_header.svg" /></a>
					</div>

						<div className="pan">
							<div><a href="/stationary_index/">TOP</a></div>
							<div><a href={'/guide/'}>ショッピングガイド</a></div>
							<div>お支払いについて</div>
						</div>
					<h1>SHOPPING GUIDE<p>ショッピングガイド</p></h1>
 </Container>

<Container fluid={true}>
   <Row>
        <ul className="sub-menu">
							<li><a href="/guide/">ご注文の流れ</a></li>
							<li><a href="/guide/payment/">お支払いについて</a></li>
							<li><a href="/guide/send/">配送・送料について</a></li>
							<li><a href="/guide/#wrapping">ラッピングについて</a></li>
							<li><a href="/guide/faq/">よくあるご質問</a></li>
							<li><a href="/member/l">会員登録のおすすめ</a></li>
         </ul>
     </Row>
 </Container>

<Container className="longtxt">
	 <Row>
	 <Col lg={{ span: 10, offset: 1 }} md={12}>
<Container>
    <h2>お支払いについて</h2>
<Row>
<Col md={4} mt={5}>
        <Row>
							<Col sm={4} md={12} xs={4}>
  <div className="guide-pat"><Image src="/svg/icon-payment01-2.svg" /></div>
          </Col>
								<Col sm={8} md={12} xs={8}>
                <h3 className="guide-pat-title">クレジットカード決済</h3>
    <p>ご注文時にご使用になるカード情報をご入力いただき決済を行います。</p>
    </Col>
    </Row>
  <ul className="guide-point">
  <li>
    <picture>
<source media="(max-width: 767px)" srcset="/svg/img-card02.svg" />
<Image src="/svg/img-card.svg" fluid alt="取り扱いクレジットカード" />
</picture>
              </li>
  <li>1回払いのみ</li>
  </ul>
</Col>

  <Col md={4} mt={5} mb={5}>
                    <Row>
<Col md={12} sm={4} xs={4}>
  <div className="guide-pat"><Image src="/svg/icon-payment03-2.svg" /></div>
          </Col>
              <Col md={12} sm={8} xs={8}>
               <h3 className="guide-pat-title">GMO後払い決済</h3>
    <p>お客様の手元に商品が到着した後に代金をお支払い頂く決済方法です。</p>
    </Col>
</Row>
          <ul className="guide-point">
  <li>コンビニ・銀行・郵便局</li>
  <li>{/*後払い手数料236円(税込)*/}後払い手数料266円(税込)</li>
    <li>与信があります</li>
  </ul>
</Col>

<Col md={4} mt={5} mb={5}>
    <Row>
  <Col md={12} sm={4} xs={4}>
  <div className="guide-pat"><Image src="/svg/icon-payment02-2.svg" /></div>
          </Col>
                <Col md={12} sm={8} xs={8}>
    <h3 className="guide-pat-title">代金引換</h3>
    <p>代金は商品配達時に運送業者の配達員へ現金でお支払いください。</p>
    </Col>
      </Row>
          <ul className="guide-point">
  <li>宅急便のみ利用可能</li>
  <li>代引手数料330円(税込)～</li>
    <li>お届け時に現金でお支払い</li>
  </ul>
    </Col>
</Row>
</Container>

<Container>
<h3><span>クレジットカード決済</span></h3>
 <div className="stripe-info">
	 <Row>
		 <Col sm={12} md={4}><img src="/img/stripe_logo.svg" fluid alt="Stripeロゴ" /></Col>
	 <Col sm={12} md={8}>多くのご要望により「JCBカード」がご利用いただけるよう、オンライン決済サービスを「Stripe」に移行しました。<br />
これまでの「Square」決済とは異なりますので、カード情報の再登録が必要となります。<br />
お手数をお掛けして申し訳ございませんが、何卒ご理解いただけますようお願い申し上げます。
</Col>
</Row>
</div>

 <p className="red exclamation">北海道・沖縄県の方は追加差額660円をご請求させていただきます。</p>
 <p>商品の欠品、追加などにより、決済価格に変動のあった場合は、「Stripe」を経由して差額の返金、追加請求書の発行をメールにてご案内させていただきます。</p>
<p>支払い方法選択画面で、「クレジットカード」専用の決済画面が表示されるので、ご利用のクレジットカード番号と有効期限をご入力いただき、決済をお済ませ下さい。</p>
<p>カード情報の詳細が弊社側に開示されることはありませんので、弊社から情報が漏洩する可能性はございません。<br />
<a href="https://stripe.com/about" target="_blank" rel="noopener noreferrer"><i className="fa fa-arrow-right"></i> Stripe公式ホームページはこちら</a></p>
				<Container>
<div className="caution">
<h4 className="text-left">ご注意</h4>
ご注文後のキャンセルや変更はお受けできない場合もございます。ご注意ください。<br />
また、<span className="marker_y">ご注文キャンセルが発生した場合には、ご返金の手続きに時間がかかる場合がございます。</span>あらかじめご了承下さい。
</div>
</Container>
				</Container>

				<Container>
<h3><span>代金引換</span></h3>
 <p>代金は商品配達時に運送業者の配達員へ現金でお支払いください。<br />
一部離島につきましては、代金引換がご利用できない場合がございます。あらかじめご了承ください。</p>
{/*<p className="red exclamation">ご請求先・ご注文者情報とお届け先情報が異なる場合は、代金引換のお支払方法はご利用いただけません。ご了承の程よろしくお願い申し上げます。</p>*/}
<p className="red exclamation">［メーカー発送］商品は代金引換のお支払方法はご利用いただけません。<br />［メーカー発送］商品のみの代金引換のご注文はキャンセルさせていただきます。<br />また、［メーカー発送］商品と通常商品をご一緒にご注文されている場合は、［メーカー発送］商品のみキャンセルし、通常商品のみのお届けとなりますので、あらかじめご理解の上、ご注文くださいませ。</p>
         <div className="card">
   <h4 className="card-title"><span>代引手数料</span></h4>
 <div className="card-body">
<table className="table table-bordered text-center mb-5">
                               <thead className="thead-dark">
                                        <tr>
                                            <th>商品代金＋送料(税込)</th>
                                            <th>手数料(税込)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1万円以下</td>
                                            <td>330円</td>
                                        </tr>
                                        <tr>
                                            <td>3万円以下</td>
                                            <td>440円</td>
                                        </tr>
                                        <tr>
                                            <td>10万円以下</td>
                                            <td>660円</td>
                                        </tr>
                                        <tr>
                                            <td>10万円以上</td>
                                            <td>1,100円</td>
                                        </tr>

                                    </tbody>
                                </table>
   <p className="red exclamation">ヤマト運輸「宅急便コレクト」による代金引換ですが、現金のみの取り扱いとなっております。<br />
現金以外のクレジットカード・デビットカード、電子マネーでのお支払いはご利用いただけません。あらかじめご了承ください。</p>
</div>

</div>
		</Container>

		<Container>
<h3><span>ＧＭＯ後払い（コンビニ・銀行・ゆうちょ銀行）</span></h3>
<p className="red exclamation">11月15日12：00より手数料は266円へ変更となりましたので、あらかじめご了承ください。</p>{/**/}
<p>商品出荷後、商品とは別にGMOペイメントサービス株式会社から払込票がご注文者様宛に郵送されます。お近くのコンビニエンスストア・郵便局（ゆうちょ銀行）・銀行にてお支払いくださいませ。</p>
<ul className="guide-ul">
  <li>郵便局でのお支払いには、GMOペイメントサービス株式会社からお送りする払込票をご利用ください。電信振替はご利用いただけません。</li>
<li>銀行振込の場合の手数料はお客様負担となります。また、振込先の口座番号はお取引ごとに毎回異なりますので、請求書に記載された口座番号をご確認ください。</li>
</ul>

<Row>
<Col sm={6} mb={3}>
                  <div className="caution">
<h4>お支払い期限</h4>
請求書発行から14日以内にお支払いください。お支払い期限は請求書にも記載しております。
{/* <br />お支払い期限を一定期間過ぎてもお支払いの確認がとれない場合、ご請求金額に回収事務手数料297円（税込）が加算されます。（最大3回、合計891円） */}
<Image src="/img/guide/atobarai_convenience.png" alt="後払い決済ご利用可能店舗一覧" className="img-fluid te mx-auto d-block my-3" />
GMO後払いサービスについて詳しくは、<a href="https://www.gmo-ps.com/" target="_blank" rel="noopener noreferrer">こちら</a>をご確認ください。
</div>
</Col>
          <Col sm={6} mb={3}>
                    <div className="caution">
<h4>ご注意</h4>
<span className="marker_y">ご利用にあたり審査がございます。</span><br />
審査結果によっては「GMO後払い」をご利用いただけない場合がございますので、その場合には別のお支払方法 へ変更をお願いします。<br />
「GMO後払い」はGMOペイメントサービス株式会社が提供するサービスです。当社はGMOペイメントサービス株式会社に対しサービスの範囲内で個人情報を提供し、代金債権を譲渡します。<br />
GMO後払いサービスの、<a href="https://www.gmo-ps.com/customer/" target="_blank" rel="noopener noreferrer">注意事項</a>および、、<a href="https://www.gmo-ps.com/customer/privacy_operation/" target="_blank" rel="noopener noreferrer">プライバシーポリシー</a>に同意のうえ、GMO後払いサービスをご利用ください。
</div>
  </Col>
</Row>

<br />
<p className="mt-5 pt-5"><span className="marker_y">ご利用限度額はGMO後払い累計で、55,000円（税込）</span>です。</p>
<ul className="guide-ul">
<li>ご利用者が未成年の場合、法定代理人の利用同意を得てご利用ください。</li>
<li>商品のお届け先が「運送会社留め」「郵便局留め」、また「学校」「病院」「ホテル」など一時滞在となるご住所の場合は、後払いサービスをご利用いただけません。</li>
   <li>後払い決済をご利用の場合、商品の転送はお受けできません。予めご了承ください。</li>
   <li>お届け先が勤務先の場合はご利用いただけますのでご住所の後に「勤務先」とご入力ください。</li>
   <li>ギフトのお届け先が一時滞在となるご住所の場合にはご利用になれます。</li>
   
</ul>
</Container>
</Col>
</Row>
</Container>
				</main>
									<Footer/>
										</div>
		);
	}
}

export default withRouter(GuidePayment);
